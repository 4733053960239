import React, {useEffect} from 'react';
import Modal from 'react-modal';
import EstimateModal from './EstimateModal';
import mobileCoverImage from './images/mobileCoverPhoto.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Cover.css';




Modal.setAppElement('#root');





  function Cover() {
    

    useEffect(() => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = mobileCoverImage;
      document.head.appendChild(link);
    }, []);


  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    AOS.init();
  }, []);

    
    return (
       <div className='coverContainer' id="coverContainer">

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className='Modal'
                contentLabel="Modal"
                
                >
                  <EstimateModal handleClick = {closeModal}/>   
            </Modal>
        
        
         <div className='contentContainer'>

                <div className='desktopHeaderContainer'>
                <div className='desktopTextContainer'>
                    <h1 className='desktopCoverText'>You Dream It! </h1>
                </div>
                <div className='desktopTextContainer'>
                    <h1 className='desktopCoverText secondHeader'>We Build It!</h1>
                </div>
                </div>
                
                <div className='desktopSubTextContainer'>
                    <h1 className='desktopCoverSubText'>We help build your dreams with trust and professional expertise </h1>
                </div>
                
                <h1 className='mobileCoverText' data-aos="fade-up">You Dream It! <br /> We Build It! </h1>
                <div className='coverButtonContainer'>
                <button onClick={openModal} className='coverButton' data-aos="fade-up">Request an Estimate</button>
                
                </div>
                

                </div>
         
       </div> 
    
    )
}
  
export default Cover;