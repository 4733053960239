import './Services.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect} from "react";
import { Link } from "react-router-dom";
import ServicesIcon from './ServicesIcon';
import ServicesImage from './images/services.jpg';
import LightingIcon from './images/lighting.svg';
import DeckIcon from './images/deck.svg';
import GardenIcon from './images/garden.svg';
import DrainageIcon from './images/drainage.svg';
import SodIcon from './images/sod.svg';
import GradingIcon from './images/bulldozer.svg';
import WalkwayIcon from './images/walkway.svg';
import RetainingWallIcon from './images/wall.svg';
import FireFeatureIcon from './images/fireplace.svg';
import RenovationsIcon from './images/toolbox.svg';
import StairsIcon from './images/stairs.svg';
import Interlock from './images/driveway.svg';


function Services() {
   useEffect(() => {
      AOS.init();
    }, [])
    return (
       <div className='servicesContainer' id="servicesSection">
         <div className='servicesImageContainer'>
            <img className='servicesImage' src={ServicesImage} alt="backyard patio" data-aos="fade-up"/>
            </div>
            <div className='IconContainer'> 
            <div className='titleContainer'>
               <h1 className='servicesTitle'> Services </h1>
            </div>
            <div className='desktopIconContainer'>
                  <div className='serviceRow' data-aos="fade-up">
                     <div className='column'>
                       <div className='servicesIcon'>
                         <Link to={`service/walkways-patios`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={WalkwayIcon} iconTitle="Walkways & Patios"/>  </Link>                 
                        </div>
                     </div>  
                     <div className='column'>
                        <div className='servicesIcon'>
                         <Link to={`service/retaining-walls`} onClick={()=>{window.scrollTo(0,0);}} ><ServicesIcon image={RetainingWallIcon} iconTitle="Retaining Walls"/></Link>                   
                      </div>
                     </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/drainage`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={DrainageIcon} iconTitle="Drainage" /></Link>
                        </div>
                     </div> 
                     <div className='column'>
                      <div className='servicesIcon'>
                        <Link to={`service/sodding-grading`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={SodIcon} iconTitle="Grading & Sod" /></Link>
                      </div>
                     </div> 
                  </div>
                  <div className='serviceRow' data-aos="fade-up">       
                     <div className='column'>
                        <div className='servicesIcon'>
                         <Link to={`service/carpentry`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={DeckIcon} iconTitle="Carpentry"/></Link>
                        </div>
                     </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/water-fire-features`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={FireFeatureIcon} iconTitle="Water/Fire Features"/></Link>
                        </div>
                     </div>
                     <div className='column'>
                       <div className='servicesIcon'>
                         <Link to={`service/steps`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={StairsIcon} iconTitle="Steps"/>  </Link>                 
                        </div>
                     </div>
                     <div className='column'>
                       <div className='servicesIcon'>
                         <Link to={`service/excavating`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={GradingIcon} iconTitle="Excavating"/>  </Link>                 
                        </div>
                     </div>
                  </div>
                  <div className='serviceRow' data-aos="fade-up"> 
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/gardens`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={GardenIcon} iconTitle="Gardens"/></Link>
                        </div>                   
                     </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/driveways`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={Interlock} iconTitle="Driveways"/></Link>
                        </div>                   
                     </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/landscape-lighting`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={LightingIcon} iconTitle="Landscape Lighting"/></Link>
                        </div>                   
                     </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/interior-renovations`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={RenovationsIcon} iconTitle="Interior Renovations"/></Link>
                        </div>                   
                     </div>
                  </div>
               </div>
               <div className='mobileIconContainer'>
                  <div className='serviceRow' data-aos="fade-up">
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/walkways-patios`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={WalkwayIcon} iconTitle="Walkways and Patios"/></Link>
                        </div>
                     </div>
                     <div className='column'>
                       <div className='servicesIcon'>
                         <Link to={`service/retaining-walls`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={RetainingWallIcon} iconTitle="Retaining Walls"/>  </Link>                 
                        </div>
                     </div>
                     
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/drainage`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={DrainageIcon} iconTitle="Drainage" /></Link>
                        </div>
                     </div>
                  </div>
                  <div className='serviceRow' data-aos="fade-up">
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/sodding-grading`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={SodIcon} iconTitle="Grading & Sod" /></Link>
                        </div>
                     </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/carpentry`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={DeckIcon} iconTitle="Carpentry"/></Link>
                        </div>
                     </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/water-fire-features`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={FireFeatureIcon} iconTitle="Water/Fire Features"/></Link>
                        </div>
                     </div>  
                     
                  </div>
                  <div className='serviceRow' data-aos="fade-up">
                     
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/steps`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={StairsIcon} iconTitle="Steps"/>  </Link>                 
                        </div>
                     </div>
                     <div className='column'>
                       <div className='servicesIcon'>
                         <Link to={`service/excavating`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={GradingIcon} iconTitle="Excavating"/>  </Link>                 
                        </div>
                     </div>          
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/gardens`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={GardenIcon} iconTitle="Gardens"/></Link>
                        </div>                   
                     </div>
                  </div>
                  <div className='serviceRow' data-aos="fade-up">
                     <div className='column'>
                       <div className='servicesIcon'>
                          <Link to={`service/driveways`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={Interlock} iconTitle="Driveways"/></Link>
                       </div>
                      </div>
                     <div className='column'>
                        <div className='servicesIcon'>
                           <Link to={`service/landscape-lighting`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={LightingIcon} iconTitle="Landscape Lighting" /></Link>
                         </div>
                     </div>
                     <div className='column'>
                       <div className='servicesIcon'>
                         <Link to={`service/interior-renovations`} onClick={()=>{window.scrollTo(0,0);}}><ServicesIcon image={RenovationsIcon} iconTitle="Interior Renovations"/>  </Link>                 
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div>
       </div>  
    )
}
  
export default Services;