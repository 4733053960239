import React from 'react';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet-async';
import coverPhoto from '../components/images/coverPhoto.jpeg'
import EstimateModal from '../components/EstimateModal';
import './Service.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect} from "react";
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';




function Service(props) {
    useEffect(() => {
        AOS.init();
      }, [])
      

      const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
   
    return (
       <div className='serviceContainer' id="serviceSection">
        <Helmet>
               { /* Standard metadata tags */ }
               <title>Munt's Landscaping | {props.title}</title>
               <meta name='description' content="We do landscaping differently. Quality craftmanship and locally-owned." />
               { /* End standard metadata tags */ }
               { /* Facebook tags */ }
               <meta property="og:image" content={coverPhoto} />
               <meta property="og:title" content="Munt's Landscaping" />
               <meta property="og:description" content="We do landscaping differently. Quality craftmanship and locally-owned." />
               { /* End Facebook tags */ }
     
          </Helmet>
        <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className='Modal'
                contentLabel="Example Modal"
                >
                  <EstimateModal handleClick = {closeModal}/>   
            </Modal>
        
        <NavBar />
            <div className='serviceHeaderContainer'>
                <span className='serviceHeader'>{props.title} Services</span>  
            </div>
            <p className='serviceParagraph'>For over 10 years, Munt’s Landscaping has been a family owned, design and build landscape construction company. We specialize in building high quality outdoor living spaces for our customers in the Greater Hamilton Area. We are an expert in the landscaping industry and strive to be the best at what we do while making our client’s dreams a reality. At Munt’s Landscaping, we create beautiful, functional, long lasting landscapes. With over a decade of industry experience, you can trust us to tackle your next landscape renovation. </p>
            <div className='row'>
                <div className='smallColumn'>
                    <div className='imgContainer' data-aos="fade-up">
                        <img className='serviceImage' src={props.img1} alt="service" />
                    </div>
                </div>
                <div className='bigColumn'>
                    <p className='serviceParagraph'> {props.paragraph}</p>
                    <p className='serviceParagraph'> Munt's Landscaping is offering <span className='locationParagraph'>{props.title}</span> services for the Southern Ontario region including areas such as Hamilton, Burlington, Oakville, the Niagara Region and Haldimand County.</p>
                </div>  
            </div>
            <div className='row'>
                <div className='bigColumn'>
                <p className='serviceParagraph'> We are devoted to providing first class customer service, along with outstanding craftsmanship, to every project that we work on. One of our goals is to not just meet, but exceed customer expectations and to build ongoing relationships. We strive to give all customers the greatest and most flawless experience possible. Each project we complete will be built using above industry standard building practices, while maintaining open and steady communication throughout to ensure our customer is thrilled with the finished product.  </p>
                </div>
                <div className='smallColumn'>
                    <div className='imgContainer' data-aos="fade-up">
                        <img className='serviceImage' src={props.img2} alt="service" />
                    </div>
                </div>  
            </div>
            <br />
            <div className='requestEstimateContainer'>
                <h1 className='estimateText'>Ready to get a <span className='estimateTitle'>{props.title}</span> estimate?</h1>
                <h1 className='estimateText'>We are ready for you.</h1>
                <div className='serviceButtonContainer' data-aos="fade-up">
                    <button onClick={openModal} className='serviceButton'>Request an Estimate</button>
                </div>
            </div>
            
        <Footer />    
       </div> 
    
    )
}
  
export default Service;