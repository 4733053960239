import './Confirmation.css';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';


function Confirmation(props) {
  
    return (
       <div className='estimateConfirmationContainer'>
            <NavBar />
            <div className="estimateConfirmationTextContainer">
            <h1 className='estimateConfirmationText'>
                Thanks for reaching out to us!
            </h1>
            <br />
            <h2 className='estimateConfirmationText'>
                We have received your {props.confirmationType}.  We will contact you within 1 business day to address your query.  
            </h2>
            </div>
            <Footer />
       </div> 
    
    )
}
  
export default Confirmation;