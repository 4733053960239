import React from 'react';
import './Footer.css';
import Logo from './images/logo.svg';
import Modal from 'react-modal';
import EstimateModal from './EstimateModal';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { BsInstagram } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";


function Footer() {
    const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  
    return (
       <div className='footerContainer'>
        <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className='Modal'
                contentLabel="Example Modal"
                >
                  <EstimateModal handleClick = {closeModal}/>   
            </Modal>
            <div className='row'>
                <div className='footerColumn'>
                    <h2 className='footerHeader'>CALL US</h2>
                    <a href="tel:905-537-3195" className='footerText'>(905) 537-3195</a>
                  

                </div>
                <div className='footerColumn'>
                    <h2 className='footerHeader'>EMAIL US</h2>
                    <p className='footerText'>muntslandscaping@gmail.com</p>
                  

                </div>
                <div className='footerColumn'>
                    <h2 className='footerHeader'>GET AN ESTIMATE</h2>
                    <button onClick={openModal} className='footerButton'>Request an Estimate</button>
    
                    
                </div>
            </div>
            
                <div className='logoContainer'>
                    <button className='socialMediaButton'><a href='https://www.facebook.com/profile.php?id=100094595036098&mibextid=LQQJ4d'><BsFacebook /></a></button>
                    <img className='footerLogo' src={Logo} alt='logo'/>
                    <button className='socialMediaButton'><a href='https://instagram.com/muntslandscaping?igshid=MjEwN2IyYWYwYw=='><BsInstagram /></a></button>
                </div>
                <div className='copyrightContainer'>
                    <p className='copyrightText'> © Copyright Munt's Landscaping.  All Rights Reserved.</p>
                </div>
                <div className='creditDesktopContainer'>
                    <div className='creditColumn'>
                        <ul className='footerLiContainerNavbar'>
                            
                            <li className='footerNavbarLi'><HashLink smooth to="/#aboutSection">About</HashLink></li>
                            <li className='footerNavbarLi'><HashLink smooth to="/#servicesSection">Services</HashLink></li>
                            <li className='footerNavbarLi'><HashLink smooth to="/#processSection">How it Works</HashLink></li>
                            <li className='footerNavbarLi'><Link to={`/contact`} onClick={()=>{window.scrollTo(0,0);}}>Contact</Link></li>
                        </ul>
                        
                    </div>
                    <div className='creditColumn'>
                        <ul className='footerLiContainerCredits'>
                            <li className='footerCreditLi'><Link to={`/contact`}>website design by Walter De Jong</Link></li>
                        </ul>
                        
                    </div>
                </div>
                <div className='creditMobileContainer'>
                        <ul className='footerLiContainerNavbar'>
                            <li className='footerMobileNavbarLi'><HashLink smooth to="/#aboutSection">About</HashLink></li>
                            <li className='footerMobileNavbarLi'><HashLink smooth to="/#servicesSection">Services</HashLink></li>
                            <li className='footerMobileNavbarLi'><HashLink smooth to="/#processSection">How it Works</HashLink></li>
                            <li className='footerMobileNavbarLi'><Link to={`/contact`}>Contact</Link></li>
                        </ul>
                </div>
                <div className='footerMobileCredits'><Link to={`/contact`}>website design by Walter De Jong</Link></div>
        </div>
       
    )
}
  
export default Footer;