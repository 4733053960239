import { useEffect, useState } from 'react';
import './Reviews.css';
import { FaStar } from 'react-icons/fa';



function Reviews() {

    const review1= `"Dylan was a pleasure to work with.  The process was streamlined and he was constantly updating us and making sure we were on the same page."`;
    const review2= `"Munt's Landscaping helped build our deck for our backyard.  We are currently sitting on it and loving every moment of it."`;
    const review3 = `"Love the backyard.  Thanks Munt's Landscaping!"`;
    const reviews =[review1, review2, review3];

    let [review, setReview] = useState(0);

    useEffect(()=> {
        const interval = setInterval(()=>{
            if(review === 2){
                setReview(review = 0);
            }else {
                setReview(review += 1);
            }
        }, 4000)
        return () => clearInterval(interval);
    }, []);



    return (
       <div className='reviewsContainer'>

        <div className='row'>
            <div className='textColumn'>
                <h1 className='reviewsTitle'>Hear what our clients have to say.</h1>
                <div className='starContainer'>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                </div>
              
                
           </div>
           <div className='reviewColumn'>
                <h1 className='reviewsText'>{reviews[review]}</h1>
           </div>
        </div>
       </div> 
    
    )
}
  
export default Reviews;