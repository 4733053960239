import './Steps.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect} from "react";
import estimateImage from './images/estimate.svg';
import meetingImage from './images/meeting.svg';
import designImage from './images/design.svg';
import finishedYardImage from './images/finishedyard.svg';



function Steps() {
    useEffect(() => {
        AOS.init();
      }, [])
  
    return (
       <div className='stepsContainer' id="processSection">
        <h1 className='stepsTitle'>Our Process</h1>
           <div className='row' data-aos="fade-up">
            
                <div className='cardColumn'>
                    <img className='stepsIcon' src={estimateImage} alt="estimate" />
                     <h1 className='stepsHeader'> Request an Estimate </h1>
                    
                </div>
                
       
                <div className='cardColumn'>
                <img className='stepsIcon' src={meetingImage} alt="meeting"/>
                    <h1 className='stepsHeader'> On-Site Consultation </h1>
                </div>
                <div className='cardColumn'>
                <img className='stepsIcon' src={designImage} alt="design"/>
                    <h1 className='stepsHeader'> Design Your Dream </h1>
                </div>
                <div className='cardColumn'>
                <img className='stepsIcon' src={finishedYardImage} alt="finished yard" />
                    <h1 className='stepsHeader'> Complete Your Project </h1>
                </div>
           </div>
           <div className='row'>
                <div className='paragraphColumn'>
                    <hr />
                     <p>The hardest part is getting started.  But we make it easy at Munt's Landscaping. You can just click on the get an estimate button and we will provide you with a free quote.</p>
                  
                </div>
                <div className='paragraphColumn'>
                <hr />
                     <p>It's important to find a good fit when achieving your landscaping goals.  At this stage we will meet with you and ensure transparency and trust. </p>
                </div>
       
                <div className='paragraphColumn'>
                <hr />
                     <p> After our initial meeting we will have the specifications needed to create an landscape design and plan.  This will have to be approved by you before we can get started.</p>
                </div>
       
                <div className='paragraphColumn'>
                    <hr />
                     <p> Throughout our work, we will keep you updated and continuously ask for your feedback.  This is our favourite part of the process because we can make our customers happy. </p>
                </div>
           </div>
       </div> 
    
    )
}
  
export default Steps;