import './Error.css';
import coverPhoto from '../components/images/coverPhoto.jpeg'
import { Helmet } from 'react-helmet-async';
import Logo from '../components/images/logo.svg';
import { Link } from "react-router-dom";
import {useRouteError} from 'react-router-dom';


function Error() {
    const error = useRouteError();
    console.error(error);

  
    return (
       <div className='errorContainer'>
        <Helmet>
               { /* Standard metadata tags */ }
               <title>Munt's Landscaping | Error</title>
               <meta name='description' content="Oops.  We seemed to have made an error.  Lets get back on track." />
               { /* End standard metadata tags */ }
               { /* Facebook tags */ }
               <meta property="og:image" content={coverPhoto} />
               <meta property="og:title" content="Munt's Landscaping" />
               <meta property="og:description" content="We do landscaping differently. Quality craftmanship and locally-owned." />
               <link rel="canonical" href='muntslandscaping.ca' />
               { /* End Facebook tags */ }
     
          </Helmet>
            <div className='logoContainer'>
                <a href="/"><img className='errorLogo' src={Logo} alt="logo" /></a>
            </div>
            <div className='textContainer'>
                <h1 className='errorHeader'>Oops...</h1>
                {console.log(error.message + "  " + error.statusText)}
            </div>
            <div className='textContainer'>
                <h2 className="errorText">It seems we made a mistake. Let's get back on track</h2>
            </div>
            <div className='buttonContainer'>
            <Link to={`/`}><button className='footerButton'>Go To Home</button></Link>
            </div>
       </div> 
    
    )
}
  
export default Error;