import './ServicesIcon.css';



function ServicesIcon(props) {
  
    return (
       <div className='servicesIconContainer'>

        <div className='iconContainer'>
            <img className='icon' src={props.image} alt={props.iconTitle}/> 
            <h1 className='iconText'>{props.iconTitle}</h1>
            </div>
       </div> 
    
    )
}
  
export default ServicesIcon;