import React from 'react';
import './NavBar.css';
import Logo from './images/logo.svg'
import Modal from 'react-modal';
import EstimateModal from './EstimateModal';
import { FaBars } from 'react-icons/fa';
import { FaPhoneSquare } from 'react-icons/fa';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';



function NavBar() {

    const [toggle, setToggle] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    

    function openModal() {
      setIsOpen(true);
   }

   function afterOpenModal() {
    // references are now sync'd and can be accessed.
   }

    function closeModal() {
     setIsOpen(false);
   }
  
    return (
      
      
      <div>
        <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className='Modal'
                contentLabel="Estimate Modal"
                >
                  <EstimateModal onClick = {closeModal}/>   
            </Modal>
       <div className='navbarDesktopContainer'>
          <div className='logoColumn'>
            <div className='logo-container'>
              <a href="/"><img className='logo' src={Logo} alt="logo" /></a>
            </div>
          </div>
            <div className='listColumn'>
               <div className='menu-list-container'>
                 <ul className="full-screen-ul">
                     <li className='full-screen-li'><HashLink smooth to="/#aboutSection">About</HashLink></li>
                     <li className='full-screen-li'><HashLink smooth to="/#servicesSection">Services</HashLink></li>
                     <li className='full-screen-li'><HashLink smooth to="/#processSection">How it Works</HashLink></li>
                     <li className='full-screen-li'><Link to={`/contact`}>Contact</Link></li>
                  </ul>
                </div>
            </div>
            <div className='buttonColumn'>
              <div className='navbar-button-container'>
                <button onClick={openModal} className='navbar-button'>Request an Estimate</button>
               </div>
            </div>
          </div>


          <div className='mobileNavbarContainer'>

            <div className='navbarMobileEndColumn'>
              <div className='logo-container'>
                 <a href="/"><img className='logo' src={Logo} alt="logo" /></a>
              </div>
            </div>

            <div className='navbarMobileMiddleColumn'>
            <div className='phoneContainer'>
                  <div className='phoneIcon'>
                    
                    <button className='phoneButton' > <a href="tel:905-537-3195" className='phoneText'><FaPhoneSquare className='phoneIcon'/> (905) 537-3195</a></button>
                  </div>
              </div>
              
            </div>

            <div className='navbarMobileEndColumn'>
              <button className='triple-bars' title="Menu" aria-label="Menu" onClick={()=>setToggle(!toggle)}><FaBars /></button>
            </div> 
           </div>
           {toggle &&
               <div className='mobile-menu responsive'>
                  <ul>
                  <li className='mobileLi'><HashLink smooth to="/#aboutSection">About</HashLink></li>
                     <li className='mobileLi'><HashLink smooth to="/#servicesSection">Services</HashLink></li>
                     <li className='mobileLi'><HashLink smooth to="/#processSection">How it Works</HashLink></li>
                     <li className='mobileLi'><Link to={`/contact`}>Contact</Link></li>
                  </ul>
                </div> 
              } 
           </div>
     
      
    
    )
}
  
export default NavBar;
  