import './Home.css';
import { Helmet } from 'react-helmet-async';
import coverPhoto from '../components/images/coverPhoto.jpeg'
import NavBar from '../components/NavBar';
import Cover from '../components/Cover';
import Mission from '../components/Mission';
import Reviews from '../components/Reviews';
import Services from '../components/Services';
import Steps from '../components/Steps';
import About from '../components/About';
import Footer from '../components/Footer';
import ReactGA from "react-ga4";



function Home() {
     ReactGA.initialize("G-CKHP38YN2R");
     ReactGA.send({ hitType: "pageview", page: "/" });

    
    return (
       <div className='homeContainer'>
          <Helmet>
               { /* Standard metadata tags */ }
               <title>Munt's Landscaping</title>
               <meta name='description' content="We do landscaping differently. Quality craftmanship and locally-owned." />
               { /* End standard metadata tags */ }
               { /* Facebook tags */ }
               <meta property="og:image" content={coverPhoto} />
               <meta property="og:title" content="Munt's Landscaping" />
               <meta property="og:description" content="We do landscaping differently. Quality craftmanship and locally-owned." />
               { /* End Facebook tags */ }
     
          </Helmet>
            <NavBar />
            <Cover />
            <Mission />
            <div className="mobileReviews">
                 <Reviews />
            </div>
            <Steps />
            <div className="desktopReviews">
                 <Reviews />
            </div>
            <Services />
            <About />
            <Footer />
        </div> 
        
    )
}
  
export default Home;