import React,{ useRef } from 'react';
import './Contact.css';
import coverPhoto from '../components/images/coverPhoto.jpeg'
import { Helmet } from 'react-helmet-async';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";


function Contact() {
   
    const form = useRef();
    const navigate = useNavigate();
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const emailTemplateID = process.env.REACT_APP_CONTACT_TEMPLATE_ID;
    const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const sendEmail = (e) => {
     e.preventDefault();
     navigate("/contact-confirmation");
        emailjs.sendForm(serviceID, emailTemplateID, form.current, emailjsPublicKey)
          .then((result) => {
          console.log(result.text);
          }, (error) => {
          console.log(error.text);
         }).then(
            
         );   
    };
  
    return (
       <div className='contactContainer'>
        <Helmet>
               { /* Standard metadata tags */ }
               <title>Munt's Landscaping | Contact</title>
               <meta name='description' content="We do landscaping differently. Quality craftmanship and locally-owned." />
               { /* End standard metadata tags */ }
               { /* Facebook tags */ }
               <meta property="og:image" content={coverPhoto} />
               <meta property="og:title" content="Munt's Landscaping" />
               <meta property="og:description" content="We do landscaping differently. Quality craftmanship and locally-owned." />
               { /* End Facebook tags */ }
     
          </Helmet>
            <NavBar />
            <h1 className='contactHeaderContainer'> <span className='contactHeader'>Contact</span></h1>
            <br />
            <h4 className='contactText'>We are here to answer any of your questions you may have at Munt's Landscaping.  Reach out to us and we will respond as soon as we can.</h4>
            <form ref={form} onSubmit={sendEmail}>
                <div className='formContainer'>
                    <div className='formSet'>
                        <label className='formLabel'>Name</label>
                        <input className="formInput" name="name" type="text" required/>
                    </div>
                    <div className='formSet'>
                         <label className='formLabel'>Email Address</label>
                        <input className="formInput" name="email" type="text" pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$' required/>
                    </div>
                    <div className='formSet'>
                        <label className='formLabel'>Phone Number</label>
                        <input className="formInput" name="phone" type="text" pattern='^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$' required/>
                    </div>
                    <div className='formSet'>
                        <label className='formLabel'>Message</label>
                        <input className="formTextAreaInput" name="message" type="textarea" required/>
                    </div>
                    <br />
                    <div className='formSet'>
                    <input className="contactButton" type="submit" value="Submit"></input>
                    </div>
                    
                </div>
            </form>
            <Footer />
       </div> 
    
    )
}
  
export default Contact;