import landscapeLighting1 from '../components/images/coverPhoto.jpeg';
import landscapeLighting2 from '../components/images/carpentry2.jpeg';
import driveway1 from '../components/images/driveway1.jpeg';
import driveway2 from '../components/images/driveway2.jpeg';
import retainingWalls1 from '../components/images/retainingwall1.jpg';
import retainingWalls2 from '../components/images/retainingwall2.jpg';
import fireFeature from '../components/images/firefeature1.jpeg';
import carpentry1 from '../components/images/carpentry1.jpeg';
import carpentry2 from '../components/images/carpentry3.jpeg';
import garden1 from '../components/images/garden1.jpeg';
import garden2 from '../components/images/garden2.jpeg';
import drainage1 from '../components/images/drainage2.jpeg';
import drainage2 from '../components/images/drainage3.jpeg';
import steps1 from '../components/images/steps1.jpeg';
import steps2 from '../components/images/steps2.jpeg';
import patios1 from '../components/images/patio1.jpeg';
import patios2 from '../components/images/patios3.jpg';
import excavating1 from '../components/images/excavating.jpeg';
import excavating2 from '../components/images/drainage1.jpeg';
import sodding1 from '../components/images/sod1.jpg';
import sodding2 from '../components/images/sod2.jpg';
import interiorRenovations1 from '../components/images/interiorReno1.jpeg';
import interiorRenovations2 from '../components/images/interiorReno2.jpeg';


export const landscapeLighting = {title: "Landscape Lighting", img1: carpentry1, img2: landscapeLighting1, paragraph: "Landscape lighting is one of the smallest additions to a landscape that can create one of the biggest differences. An outdoor lighting system designed properly can highlight specific elements of your new landscape and transform the way you use your space once the sun goes down. Landscape lighting can take a dark space that is unusable at night to a perfectly functional and safe space, helping create security and making the navigation of walkways and steps safe for you and your family. The possibilities are endless with landscape lighting!" };
export const driveways = {title: "Driveway", img1: driveway1, img2: driveway2, paragraph: "Driveways have come a long way from just a parking pad for your vehicles.  A driveway can compliment your home's architecture, colour palette and textures, along with providing efficient parking for vehicles and trailers. Popular choices of material include: Interlocking Pavers, Asphalt and Concrete. At Munt’s Landscaping, we can build a new driveway or upgrade an existing one for you and your family. "};
export const retainingWalls = {title: "Retaining Wall", img1: retainingWalls1, img2: retainingWalls2, paragraph: "We specialize in building retaining walls of all shapes and sizes. A well built retaining wall should be functional and aesthetically pleasing in the landscape by managing the drainage of water. Ensuring longevity always begins with a properly installed base and adequate water management. Qualified building practices to accommodate these are vital in the success of any retaining wall. Our most common retaining wall installations include products such as: Precast Stone, Natural Stone Boulders, Armor Stone, Railway Ties or Dimensional Lumber."};
export const waterFireFeatures = {title: "Water & Fire Feature", img1: fireFeature, img2: landscapeLighting1, paragraph: "Water and fire are two elements that can elevate every backyard!  What better way to enjoy your outdoor space than with a fire feature. These are a fantastic option to enjoy the outdoors in the evening when entertaining company in your outdoor living space. Common fire features can include: Wood Burning Fire Pits, Natural Gas/ Propane Fire Pits or Fire Bowls. From waterfalls and ponds to bubbling rocks, the addition of any water feature in the landscape can create a warm and inviting space on your property.  Water features enhance any outdoor space incorporating a sound of nature into your front or back yard. Let us know how we can help you build a water or fire feature at your home"};
export const carpentry = {title: "Carpentry", img1: landscapeLighting2, img2: carpentry2, paragraph: "Decks, fences, sheds and other outdoor structures are common elements in most landscape projects. At Munt’s Landscaping, we pride ourselves on building high quality, long lasting decks, fences, sheds and other outdoor structures. Depending on the application, there are a wide array of material options to complete these projects. A few of these materials include: Pressure Treated Wood, Cedar, Rough Sawn Pine, Composite and Vinyl.  These elements of the landscape should be well designed and are crucial to ensure privacy, sun/shade, functionality, and flow."};
export const gardens = {title: "Garden", img1: garden1, img2: garden2, paragraph: "At Munt’s Landscaping, we can design & create a garden tailored for your property. Gardens play an important role in softening your landscape and providing an attractive backdrop for you and your family to enjoy.  A well designed garden takes a few important things into account, including: soil conditions, light, region you are located, and the size & shape of plants.  Whether you are looking for something low maintenance or you are an active gardener, we can create the right garden for you! "};
export const drainage = {title: "Drainage", img1: drainage1, img2: drainage2, paragraph: "Don't let your landscape wash away!  Improper drainage on the exterior of a home is the leading factor in water damage inside homes and landscape degradation. Stormwater management is one of the most important factors to address when designing and building a landscape. Water needs to be moved away from the home by positive grade or a drainage system. Common options include: French Drains, Dry Wells, Dry Creek Beds, Catch Basins and Channel Drains. Depending on your landscape, we can determine what is right for you."};
export const steps = {title: "Steps", img1: steps1, img2: steps2, paragraph: "Steps in the landscape can be used to take your space to the next level. Steps are most commonly used to connect two different areas when there is a grade change on the property. Steps can be used to join two areas in a backyard or to move from a walkway to a front door. Regardless of the application, steps in the landscape should be functional and safe. The type of material chosen should accent or compliment your landscape design and home. Common materials we recommend, depending on the application, include: Natural Stone Slabs, Wood or Composite, Precast Concrete, or Timbers."};
export const patiosWalkways = {title: "Patio & Walkway", img1: patios1, img2: patios2, paragraph: "Patios and walkways are one of the most common requests we get asked to build from our clients. A patio should be an extension of your home into the outdoor space, creating a place where your friends and family can enjoy the outdoors. There are many different design and build options that can be accommodated to fit each and every landscape and budget. Some examples include: Interlock Pavers, Natural Stone, Flagstone & Concrete.   "};
export const excavating = {title: "Excavating", img1: excavating1, img2: excavating2, paragraph: "Most landscape projects that come our way begin with site prep/land clearing, demolition and excavation. Having utilities located on your property is always completed before any digging occurs to ensure no disruption of service to your home. Our skilled heavy equipment operators can complete the task at hand in a safe and efficient manner. Don’t hesitate to contact Munt’s Landscaping if you require excavating or site service work."};
export const sodding = {title: "Sodding", img1: sodding1, img2: sodding2, paragraph: "Grading is the process of shaping the earth to give a smooth, desirable look that channels all stormwater where we want it to go. At Munt’s Landscaping, we have all of the tools and equipment to properly grade a property to prevent drainage problems and ensure a long lasting landscape. A laser level is always used to set grades and ensure our slopes and swales are how they need to be.  Sodding is completed after grading and is replacing a typically weak and unhealthy lawn with a new turf lawn. Sodding a lawn will eliminate erosion of your landscape with strong thriving roots.  "};
export const interiorRenovations = {title: "Interior Renovation", img1: interiorRenovations1, img2: interiorRenovations2, paragraph: "Through the winter months we offer small interior renovations to our clients. A few of our services offered are: Painting, Tiling, Flooring Installation, and Carpentry. Feel free to reach out to discuss your project to see if we would be a good fit!"};

