import React, {useRef} from 'react';
import './EstimateModal.css';
import Logo from './images/logo.svg';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";

function EstimateModal(props) {

  const form = useRef();
    const navigate = useNavigate();
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const emailTemplateID = process.env.REACT_APP_ESTIMATE_TEMPLATE_ID;
    const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const sendEmail = (e) => {
     e.preventDefault();
     navigate("/estimate-confirmation");
        emailjs.sendForm(serviceID, emailTemplateID, form.current, emailjsPublicKey)
          .then((result) => {
          console.log(result.text);
          }, (error) => {
          console.log(error.text);
         }).then(
            
         );   
    };
    
    return (
       <div className='estimateModalContainer'>
        <div className='modalHeader'>
                  <img className='logo' src={Logo} alt="logo" />
                    <h1 className='modalHeaderText'>Munt's Landscaping Estimate Request</h1>
                    <button onClick={props.handleClick} className='modalHeaderExitButton'><p className='modalHeaderExitText'>x</p></button>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                <div className='modalFormContainer'>       
                   <div className='formSet'>
                      <label htmlFor='name' className='modalLabel'>Name</label>
                      <input id='name' className='modalInput' name='name' type="text" required/>
                    </div>
                    <div className='formSet'>
                      <label htmlFor='email' className='modalLabel mobile-inline'>Email</label>
                      <input id='email' className='modalInput mobile-inline' name='email' type="text" pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$'  required/>
                    </div>
                    <div className='formSet'>
                      <label htmlFor='phone' className='modalLabel mobile-inline'>Phone</label>
                      <input id='phone' className='modalInput mobile-inline' name='phone' type="text" pattern='^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$' required/>
                    </div>
                    <div className='formSet'>
                    <label htmlFor='type' className='modalLabel serviceLabel'>Estimate Type</label>
                    </div>
                      <div className='servicesFormSet'> 
                      <select id='type' className='modalServices' name='type' defaultValue="grading">
                            <option value="fencing">Fencing</option>
                            <option value="driveways">Driveway</option>
                            <option value="retaining walls">Retaining Wall</option>
                            <option value="sodding">Sodding</option>
                            <option value="decks">Decks</option>
                            <option value="gardens">Gardens</option>
                            <option value="drainage">Drainage</option>
                            <option value="irrigation">Irrigation</option>
                            <option value="grading">Grading</option>
                            <option value="grading">Other</option>
                        </select>
                      </div>
                        <button className='modalButton'>Get Estimate</button>     
                </div>
                </form>
       </div> 
    
    )
}
  
export default EstimateModal;
