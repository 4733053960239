import './Mission.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect} from "react";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { MdOutlineDesignServices } from "react-icons/md";
import { BsPersonCheck } from "react-icons/bs";
import { MdOutlinePayment } from "react-icons/md";
import { AiOutlineStar } from "react-icons/ai";

function Mission() { 
    useEffect(() => {
        AOS.init();
      }, [])
    return (
       <div className='missionContainer'>
            <h1 className='missionTitle'>We Do Landscaping Differently</h1>
            <div className='row' data-aos="fade-up">
                <div className='missionColumn'>
                    <div className='iconContainer'>
                        <FaMapMarkerAlt className='missionIcon' />
                        <p className='missionText'>Locally Owned</p>
                    </div>
                </div>
                <div className='missionColumn'>
                    <div className='iconContainer'>
                        <VscWorkspaceTrusted className='missionIcon' />
                        <p className='missionText'>Trusted Employer</p>
                    </div>
                </div>
                <div className='missionColumn'>
                    <div className='iconContainer'>
                        <MdOutlineDesignServices className='missionIcon' />
                        <p className='missionText'>Expert Designs</p>
                    </div>
                </div>
                <div className='missionColumn'>
                    <div className='iconContainer'>
                        <BsPersonCheck className='missionIcon' />
                        <p className='missionText'>Trained Specialists</p>
                    </div>
                </div>
                <div className='missionColumn'>
                    <div className='iconContainer'>
                        <MdOutlinePayment className='missionIcon' />
                        <p className='missionText'>Simple Payment Solutions</p>
                    </div>
                </div>
                <div className='missionColumn'>
                    <div className='iconContainer'>
                        <AiOutlineStar className='missionIcon' />
                        <p className='missionText'>Top Rated</p>
                    </div>
                </div>
                
            </div>
       </div> 
    
    )
}
  
export default Mission;