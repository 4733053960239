import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home';
import Error from './pages/Error';
import Contact from './pages/Contact';
import Service from './pages/Service';
import Confirmation from './pages/Confirmation';
import {landscapeLighting, driveways, retainingWalls, sodding, carpentry, gardens, drainage, interiorRenovations, patiosWalkways, excavating, steps, waterFireFeatures  } from '../src/components/ServiceContent';
import ReactGA from "react-ga4";


function App() { 
  
  ReactGA.initialize("G-CKHP38YN2R");
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <Error />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "contact-confirmation",
      element: <Confirmation confirmationType="contact page submission"/>,
    },
    {
      path: "estimate-confirmation",
      element: <Confirmation confirmationType="estimate request"/>,
    },
    {
      path: "service/landscape-lighting",
      element: <Service title={landscapeLighting.title} img1={landscapeLighting.img1} img2={landscapeLighting.img2} paragraph={landscapeLighting.paragraph}/>,
    },
    {
      path: "service/driveways",
      element: <Service title={driveways.title} img1={driveways.img1} img2={driveways.img2} paragraph={driveways.paragraph}/>,
    },
    {
      path: "service/retaining-walls",
      element: <Service title={retainingWalls.title} img1={retainingWalls.img1} img2={retainingWalls.img2} paragraph={retainingWalls.paragraph}/>,
    },
    {
      path: "service/sodding-grading",
      element: <Service title={sodding.title} img1={sodding.img1} img2={sodding.img2} paragraph={sodding.paragraph}/>,
    },
    {
      path: "service/carpentry",
      element: <Service title={carpentry.title} img1={carpentry.img1} img2={carpentry.img2} paragraph={carpentry.paragraph}/>,
    },
    {
      path: "service/gardens",
      element: <Service title={gardens.title} img1={gardens.img1} img2={gardens.img2} paragraph={gardens.paragraph}/>,
    },
    {
      path: "service/drainage",
      element: <Service title={drainage.title} img1={drainage.img1} img2={drainage.img2} paragraph={drainage.paragraph}/>,
    },
    {
      path: "service/water-fire-features",
      element: <Service title={waterFireFeatures.title} img1={waterFireFeatures.img1} img2={waterFireFeatures.img2} paragraph={waterFireFeatures.paragraph}/>,
    },
    {
      path: "service/walkways-patios",
      element: <Service title={patiosWalkways.title} img1={patiosWalkways.img1} img2={patiosWalkways.img2} paragraph={patiosWalkways.paragraph}/>,
    },
    {
      path: "service/steps",
      element: <Service title={steps.title} img1={steps.img1} img2={steps.img2} paragraph={steps.paragraph}/>,
    },
    {
      path: "service/excavating",
      element: <Service title={excavating.title} img1={excavating.img1} img2={excavating.img2} paragraph={excavating.paragraph}/>,
    },
    {
      path: "service/interior-renovations",
      element: <Service title={interiorRenovations.title} img1={interiorRenovations.img1} img2={interiorRenovations.img2} paragraph={interiorRenovations.paragraph}/>,
    }
  ]);
 
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
   
  );
}

export default App;
