import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect} from "react";
import familyPhoto from './images/family.jpeg';

function About() {
    useEffect(() => {
        AOS.init();
      }, [])
  
    return (
       <div className='aboutContainer' id='aboutSection'>
        <div className='row'>
            <div className='textAboutColumn'>
                <h1 className='aboutTitle'>Our Story</h1>
                <p className='aboutParagraph'>Munt’s Landscaping is a small, family owned and operated business in Southern Ontario offering Landscaping and Home Improvement services. Dylan, who has been working in the
                                            landscaping industry for over 15 years, has completed post-secondary education as a
                                            Landscape Technician. He has worked throughout the industry in many roles which allowed him
                                            to grow the business to where it is today. From our modest beginnings, completing small
                                            projects for friends and neighbors, we have continued to expand to be a design and build
                                            company which serves residential and commercial customers throughout the Greater Toronto
                                            Area.</p>
                <div className='imageContainer'>
                    <img className='aboutMobileImage' src={familyPhoto} alt="family" />
                </div>
                <p className='aboutParagraph'>Munt’s Landscaping understands the value in building long lasting relationships with its customers. We stand by our core values which include:</p>
                <p className='aboutParagraph'><b>Integrity:</b> conduct our business in a legal and ethical manner, building long lasting relationships through honesty, respect and trust.</p>
                <p className='aboutParagraph'><b>Accountability and Responsibility:</b> taking pride and ownership in our work, allowing us to meet deadlines and achieve customer goals.</p>
                <p className='aboutParagraph'><b>Commitment to Success:</b> we endeavor to exceed customer expectations and go above and beyond in our work.</p>
                <p className='aboutParagraph'><b>Character:</b> above all else, we believe in being an ethical, dependable company that focuses on quality.</p>
            </div>
            <div className='imageAboutColumn' data-aos="fade-up">
                <img className='aboutImage' src={familyPhoto} alt="family" />
            </div>
        </div>

           
         
       </div> 
    
    )
}
  
export default About;